import React from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import SignOutButton from "../SignOut";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  }
}));

const TopNavigation = props => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open
      })}
    >
      <Toolbar>
        {props.isPhone ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, props.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <React.Fragment>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {Object.keys(props.items).map(key => (
                <Button
                  color="inherit"
                  key={key}
                  onClick={() => props.history.push(props.items[key])}
                >
                  {key}
                </Button>
              ))}
            </Grid>
            {props.signOut ? (
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <SignOutButton />
              </Grid>
            ) : null}
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(TopNavigation);
