import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../../Firebase";
import { withAuthorization } from "../../Session";
import ExampleRecordForm from "./ExampleRecordForm";

class ExampleRecordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exampleRecords: []
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.exampleRecords().on("value", snapshot => {
      const exampleRecordsObject = snapshot.val();
      const exampleRecordsList =
        exampleRecordsObject &&
        Object.keys(exampleRecordsObject).map(key => ({
          ...exampleRecordsObject[key],
          uid: key
        }));

      this.setState({
        exampleRecords: exampleRecordsList,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.exampleRecords().off();
  }

  render() {
    const { exampleRecords, loading } = this.state;

    return (
      <div>
        <h1>ExampleRecord</h1>
        {loading && <div>Loading ...</div>}

        <ExampleRecordForm />
        <ExampleRecordList exampleRecords={exampleRecords} />
      </div>
    );
  }
}

const ExampleRecordList = ({ exampleRecords }) => (
  <ul>
    {!!exampleRecords &&
      exampleRecords.map(exampleRecord => (
        <li key={exampleRecord.uid}>
          <span>
            <strong>name:</strong> {exampleRecord.name}
          </span>
        </li>
      ))}
  </ul>
);

const condition = authUser => !!authUser;

const ExampleRecord = compose(
  withAuthorization(condition),
  withFirebase
)(ExampleRecordPage);

export default ExampleRecord;
