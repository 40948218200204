import React from "react";
import { withFirebase } from "../Firebase";
import Button from "@material-ui/core/Button";

const SignOutButton = props => (
  <Button
    onClick={() => {
      props.firebase.doSignOut();
      if (props.onClick) {
        props.onClick();
      }
    }}
  >
    SignOut
  </Button>
);
export default withFirebase(SignOutButton);
