import React from "react";

import { AuthUserContext } from "../Session";
import * as ROUTES from "../../constants/routes";

import Navigation from "./navigation";

const authItems = {
  Home: ROUTES.HOME,
  Account: ROUTES.ACCOUNT,
  "Real Time db": ROUTES.FIREBASE_EXAMPLE
};

const publicItems = {
  Home: ROUTES.HOME_PUBLIC,
  "Sign Up": ROUTES.SIGN_UP,
  "Sign In": ROUTES.SIGN_IN
};

const NavigationPage = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <Navigation items={authItems} signOut={true} />
      ) : (
        <Navigation items={publicItems} signOut={false} />
      )
    }
  </AuthUserContext.Consumer>
);

export default NavigationPage;
