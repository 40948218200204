import React from "react";
import { AuthUserContext } from "../Session";
import HomePage from "../Home";

const HomePublic = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <HomePage />
      ) : (
        <React.Fragment>
          <h1>Home</h1>
          <p>The Home Page is accessible by every unsigned user.</p>
        </React.Fragment>
      )
    }
  </AuthUserContext.Consumer>
);

export default HomePublic;
