import React, { Component } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { createMuiTheme } from "@material-ui/core/styles";

import { SignInLink } from "../SignIn";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const SignUpPage = () => (
  <React.Fragment>
    <SignUpForm />
    <SignInLink />
  </React.Fragment>
);

const theme = createMuiTheme();

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    // eslint-disable-next-line
    const { username, email, passwordOne } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div
          style={{
            marginTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <form
            onSubmit={this.onSubmit}
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: theme.spacing(1)
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  value={username}
                  onChange={this.onChange}
                  required
                  fullWidth
                  id="unsername"
                  label="User name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  label="Confirm Password"
                  type="password"
                  id="password2"
                  autoComplete="current-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isInvalid}
              style={{ margin: theme.spacing(3, 0, 2) }}
            >
              Sign Up
            </Button>
            {error && <p>{error.message}</p>}
          </form>
        </div>
      </Container>
    );
  }
}

const SignUpLink = () => (
  <Container component="main" maxWidth="xs">
    <Grid container>
      <Grid item xs></Grid>
      <Grid item>
        <Link
          component={React.forwardRef((props, ref) => (
            <RouterLink innerRef={ref} to={ROUTES.SIGN_UP} {...props} />
          ))}
        >
          Don't have an account? Sign Up
        </Link>
      </Grid>
    </Grid>
  </Container>
);

const SignUpForm = compose(
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
