import React from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import CssBaseline from "@material-ui/core/CssBaseline";

import TopNavigation from "./TopNavigation";
import SideNavigation from "./SideNavigation";

const NavigationBase = props => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isPhone = !isWidthUp("sm", props.width);
  return (
    <React.Fragment>
      <CssBaseline />
      <TopNavigation
        {...props}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        isPhone={isPhone}
      />
      <SideNavigation
        {...props}
        handleDrawerClose={handleDrawerClose}
        open={open}
        isPhone={isPhone}
      />
    </React.Fragment>
  );
};

export default withWidth()(NavigationBase);
