import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../../Firebase";

const INITIAL_STATE = {
  name: "",
  error: null
};

class ExampleRecordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    // eslint-disable-next-line
    const { name } = this.state;
    this.props.firebase
      .exampleRecords()
      .push({
        name
      })
      .then(exampleRecord => {
        console.log("fs", exampleRecord);
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, error } = this.state;

    const isInvalid = name === "";

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="name"
          value={name}
          onChange={this.onChange}
          type="text"
          placeholder="Name"
        />
        <button disabled={isInvalid} type="submit">
          Add
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const ExampleRecordForm = compose(
  withRouter,
  withFirebase
)(ExampleRecordFormBase);

export default ExampleRecordForm;
